<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <sidebar-extract :current="3"></sidebar-extract>
          <div class="page__content">
            <h1 class="page__title">Adquirir créditos</h1>

            <div class="sidebar__header">
              <div class="tabs__flex full-width">
                <div class="tabs__filters">
                  <div class="tabs__filters--group">
                    <label class="tabs__filters--label">De</label>
                    <input
                      class="form__input tabs__filters--input"
                      type="date"
                      v-model="startDate"
                    />
                  </div>

                  <div class="tabs__filters--group">
                    <label class="tabs__filters--label">Até</label>
                    <input
                      class="form__input tabs__filters--input"
                      type="date"
                      v-model="endDate"
                    />
                  </div>
                </div>
                <button
                  type="button"
                  class="button_outline button-pix"
                  @click="$router.push(`/adquirir-credito`)"
                >
                  <img src="img/pix.png" alt="" srcset="" />
                  PIX
                </button>
              </div>
            </div>

            <div v-if="banklogs.length" class="tab__body current">
              <table class="table">
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Saldo</th>
                    <th class="table-status">Status</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="banklog in banklogs" :key="banklog.id">
                    <td data-label="Dia">
                      <span>{{ banklog.created }}</span>
                    </td>

                    <td data-label="Lançamento">
                      <span>R$ {{ banklog.value }}</span>
                    </td>
                    <td class="table-status">
                      <span
                        class="alert_card"
                        :style="
                          banklog.status == 2
                            ? 'background-color: #fff3e2; color: #d87420;'
                            : banklog.status == 1
                            ? 'background-color: #E0F8E6; color: #16b666;'
                            : 'background-color: #FFE6E0; color: #FF371A;'
                        "
                      >
                        {{ banklog.status_label }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <loading v-else-if="loading"></loading>
            <div v-else>Sem dados para mostrar</div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

const defaultForm = {
  id: '',
  type: 'PF',
  city_id: null,
  province_id: null,
  name: '',
  email: '',
  phone: '',
  company_id: 1
}
export default {
  props: {
    current: Boolean
  },
  components: {
    sidebarExtract: () => import('@/components/sidebar-extracts'),
    Loading: () => import('../../components/loading.vue')
  },
  data: () => ({
    form: { ...defaultForm },
    error: null,
    load: true,
    modal: false,
    editing: {},
    msg: null,
    collects: [],
    province_id: '',
    collectors: [],
    banklogs: [],
    collectorId: '',
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    filter: '',
    loading: true,
    startDate: '',
    endDate: ''
  }),
  computed: {
    ...mapGetters('user', ['user']),
    collectorName () {
      const [collector] = this.collectors.filter(i => i.id === this.collectorId)
      return collector ? collector.name : '-'
    },
    monthYear () {
      const date = new Date(Date.UTC(this.year, this.month))
      const formated = new Intl.DateTimeFormat('pt-BR', {
        dateStyle: 'full'
      }).format(date)
      const [year, month] = formated.split(' de ').reverse()
      return `${month} de ${year}`
    },
    extracts () {
      if (this.filter === 'entradas') return this.collects.entrada
      if (this.filter === 'saidas') return this.collects.saida
      if (this.filter === 'futuros') return this.collects.agendado
      return this.collects.extrato || []
    }
  },
  methods: {
    ...mapActions('collector', { fetchCollectors: 'fetch' }),
    ...mapActions('client', ['fetch']),

    openEditModal (item = null) {
      this.editing = {}
      if (!item) return
      const itemToEdit = { ...item }
      this.editing = itemToEdit
      this.modal = true
    },
    async deleteClient (item = null) {
      const url = 'clients/destroy'

      this.$http.delete(url, { body: { id: item } }).then(response => {
        this.msg = response.msg
      })
    },
    openModal (item = null) {
      this.selectedEntity = item
      this.modal = true
    },
    changeDate (to) {
      let { month, year } = this
      if (to === 'next') month++
      else if (to === 'prev') month--
      if (month === 0) {
        month = 12
        year--
      } else if (month === 13) {
        month = 1
        year++
      }
      this.month = month
      this.year = year
    },
    async getHits () {
      this.loading = true
      const { id } = this.user
      let url = `financial/bank-log?type=ENTRADA&receiver=RECARGA&id=${id}`
      const { startDate, endDate } = this

      if (startDate && endDate) {
        url += `&start_date=${startDate}&end_date=${endDate}`
      }

      const { data } = await this.$http.get(url)
      this.banklogs = data.data
      this.loading = false
    }
  },
  mounted () {
    this.getHits()
  },
  filters: {
    money (val) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(parseFloat(val))
    },
    date (val) {
      let date = new Date(val).getDate()
      if (date < 10) date = `0${date}`
      return date
    }
  },
  watch: {
    collectorId (val, old) {
      if (val === old) return
      this.getHits()
    },
    month (val, old) {
      if (val === old) return
      this.getHits()
    },
    startDate (val) {
      if (val && this.endDate) this.getHits()
    },
    endDate (val) {
      if (val && this.startDate) this.getHits()
    }
  }
}
</script>

<style scoped>
.table-status {
  width: 230px;
  text-align: right;
}

.button-pix {
  width: 160px;
  display: flex;
  justify-content: center;
  height: 45px;
}
.button-pix img {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}
</style>
